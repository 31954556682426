import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Card } from 'react-bootstrap';

// TODO: limit the output
const Cards = () => {
  const result = useStaticQuery(graphql`
    query Snippets {
      allMarkdownRemark(
        filter: {
          frontmatter: { published: { eq: true }, type: { eq: "snippet" } }
        }
        sort: { order: DESC, fields: [frontmatter___date] }
      ) {
        edges {
          node {
            id
            frontmatter {
              date(formatString: "DD MMMM, YYYY")
              path
              author
              title
              tags
            }
            html
          }
        }
      }
    }
  `);

  const { edges } = result.allMarkdownRemark;

  return (
    <>
      {edges.map((val) => {
        return (
          <Card border={'dark'} key={val.node.frontmatter.title}>
            <Card.Body>
              <Card.Title>{val.node.frontmatter.title}</Card.Title>
              <div
                className="card-text"
                dangerouslySetInnerHTML={{ __html: val.node.html }}
              />
              <Card.Text>
                <small className="text-muted">
                  {val.node.frontmatter.tags.map((item) => `#${item}`)}
                </small>
              </Card.Text>
            </Card.Body>
          </Card>
        );
      })}
    </>
  );
};

export default Cards;
