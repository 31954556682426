import React from 'react';
import { CardColumns } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

import { main } from '../../config/theme';
import Cards from '../components/cards';
import Layout from '../components/layout';
import { Wrapper } from '../styles/styled_blogpost';

const snippets = () => {
  return (
    <Layout>
      <Helmet title={'Repodev - Snippets'} />
      <Wrapper
        xs={12}
        sm={12}
        md={12}
        lg={10}
        className="mx-auto"
        style={{ paddingTop: 100 }}
      >
        <main className="main" style={main}>
          <h2>Useful Code Snippets & Commands</h2>
          <p>
            Some of the contents in this page are not originally from me, every
            function or command that I found it <em>very useful</em> for me or
            something that I create by myself, I will put it in here.
          </p>
          <hr />
          <CardColumns>
            <Cards />
          </CardColumns>
        </main>
      </Wrapper>
    </Layout>
  );
};

export default snippets;
